import React from "react";
import PropTypes from "prop-types";

const HomeIcon = ({ twClasses }) => {
  return (
    <div className={`rounded-full bg-secondary p-2 w-min ${twClasses}`}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.10416 13.8483V11.8038C6.10415 11.2837 6.52809 10.8612 7.05336 10.8577H8.97741C9.50518 10.8577 9.93302 11.2813 9.93302 11.8038V11.8038V13.8546C9.9329 14.2961 10.2889 14.657 10.7347 14.6673H12.0174C13.2961 14.6673 14.3327 13.6411 14.3327 12.3752V12.3752V6.55921C14.3259 6.0612 14.0897 5.59355 13.6913 5.28934L9.3045 1.79085C8.53597 1.1817 7.44349 1.1817 6.67496 1.79085L2.30737 5.29569C1.90752 5.59867 1.67094 6.0671 1.66602 6.56556V12.3752C1.66602 13.6411 2.7026 14.6673 3.98129 14.6673H5.26399C5.72092 14.6673 6.09133 14.3006 6.09133 13.8483V13.8483"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

HomeIcon.propTypes = {
  twClasses: PropTypes.string,
};

HomeIcon.defaultProps = {
  twClasses: "",
};

export default HomeIcon;
