import React from "react";

const CheckMarkIcon = () => {
  return (
    <div className="p-2 mt-px">
      <svg
        width="9"
        height="7"
        viewBox="0 0 9 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 3.373L3.374 5.746L8.12 1"
          stroke="#31CFA0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default CheckMarkIcon;
