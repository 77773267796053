import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import ServiceList from "./ServiceList";

const TestimonialCard = ({
  imageSrc,
  text1,
  text2,
  author,
  completedWorklist,
}) => {
  return (
    <div className="">
      <div
        className="bg-frameColor bg-bottom bg-no-repeat bg-cover h-85 lg:h-135 xl:rounded-xl overflow-hidden max-w-325 m-auto"
        style={{
          backgroundImage: "url('" + imageSrc + "')",
        }}
      />

      {/* White Card */}
      <div className="bg-white overflow-hidden z-10 shadow-md rounded-xl flex flex-col lg:flex-row -mt-24 lg:-mt-36 lg:m-auto lg:card-desktop mx-4">
        {/* Full Content */}
        <div className="flex flex-col md:flex-row mt-10 mb-8 lg:my-14 mx-8 lg:mx-14">
          {/* Quote Content */}
          <div className="md:w-129 lg:w-145 md:mr-8 lg:mr-4">
            <p className="h3">
              <span className="text-purple">&ldquo;</span>
              {text1}
            </p>
            <p className="body-medium mt-2">
              {text2}
              <span>&rdquo;</span>
            </p>
            <p className="body-text mt-3 md:mt-5 ">&mdash; {author}</p>
          </div>

          {/* Completed Work */}
          <ServiceList
            label="Completed Work"
            services={completedWorklist}
            ctaLabel="Our Services"
            href="/services"
            twClasses="mt-6 sm:mx-auto sm:w-66 lg:mr-10 md:-mt-2.5"
          />
        </div>
      </div>
    </div>
  );
};

TestimonialCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  completedWorklist: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TestimonialCard;
