import React from "react";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import getConfig from "next/config";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { Button } from "marketing/Button";
import ButtonPrimary from "marketing/ButtonPrimary";
import CheckMarkIcon from "marketing/CheckMarkIcon";
import ClipBoardIcon from "marketing/ClipBoardIcon";
import CoinHandIcon from "marketing/CoinHandIcon";
import Footer from "marketing/Footer";
import HomeIcon from "marketing/HomeIcon";
import ImageCard from "marketing/ImageCard";
import NavigationBar from "common/NavigationBar";
import SearchBar from "marketing/SearchBar";
import ServiceCard from "marketing/ServiceCard";
import ServiceList from "marketing/ServiceList";
import TestimonialCard from "marketing/TestimonialCard";

const { publicRuntimeConfig } = getConfig();

export default function Home() {
  const defaultMenuItems = [
    {
      title: "Overview",
      href: "/",
      active: true,
    },
    {
      title: "Our Services",
      href: "/services",
      active: false,
    },
    {
      title: "Sign In",
      href: publicRuntimeConfig.appUrl,
      active: false,
    },
  ];
  const defaultCallToAction = {
    title: "Get Started",
    altTitle: "Sign Up",
    href: publicRuntimeConfig.appUrl + "/get-started",
  };
  const availableServices = [
    "5-ft. ember free zone",
    "Landscaped defensible space",
    "Ember-resistant vents/gutter guards",
    "Deck/porch fireproofing",
    "Fire-resistant roof renovation…",
  ];

  return (
    <div>
      <Head>
        <title>Firemaps</title>
      </Head>

      {/* Nav bar */}
      <NavigationBar menuItems={defaultMenuItems} cta={defaultCallToAction} />
      {/* Hero Section */}
      <div className="relative flex flex-col bg-darkNavy bg-no-repeat bg-cover bg-bottom hero md:hero-md">
        <div className="hidden md:block">
          <Image
            layout="fill"
            className="object-center md:object-left object-cover pointer-events-none hidden md:block"
            loader={() =>
              "https://res.cloudinary.com/htlasjvip/image/upload/v1629957036/website/HeroBGWide_gruwaw.jpg"
            }
            src="image"
          />
        </div>
        <div className="block md:hidden">
          <Image
            layout="fill"
            className="object-center object-cover pointer-events-none"
            loader={() =>
              "https://res.cloudinary.com/htlasjvip/image/upload/v1629954511/website/Background_zswy6f.jpg"
            }
            src="image"
          />
        </div>

        {/* Content */}
        <div
          className="flex flex-col flex-grow z-1 relative px-4 md:px-16"
          style={{
            backgroundImage:
              "linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0))",
          }}
        >
          <div className="flex flex-grow hidden md:block"></div>

          <div className="flex h1-hero-mobile-center md:h1-hero mb-3 md:w-200 mt-8 md:mt-0 mx-auto md:mx-0">
            Wildfire defense made easy
          </div>

          <div className="text-center md:text-left text-white font-inter text-2xl font-semibold mb-32 md:mb-6 md:hero-subtitle-md">
            Get professional home hardening and defensible space work without
            the hassle.
          </div>

          <div className="flex flex-grow block md:hidden"></div>

          <div className="flex pb-20 md:pb-[280px]">
            <SearchBar
              text="Get Started"
              placeholder="Enter your home address"
              onClick={() => console.log("clicked")}
            />
          </div>
        </div>
      </div>

      {/* How it works */}
      <div className="flex flex-col bg-frameColor">
        <div className="m-auto mt-12 mb-10 mx-4 sm:mx-8">
          <div className="h2 mb-2">Your complete wildfire defense solution</div>
          <div className="m-auto max-w-2xl body-medium-center">
            Preparing your home for wildfire season is an overwhelming process
            &mdash; here’s how we make it stress free.
          </div>
        </div>
        <div className="m-auto mb-16 px-4 flex flex-col lg:flex-row lg:max-w-4xl xl:max-w-7xl">
          <div className="flex">
            <ImageCard
              imageSrc="https://res.cloudinary.com/htlasjvip/image/upload/v1629830276/website/Step1_usefbm.jpg"
              title="Schedule your free 3D inspection"
              body="One of our certified inspectors will create a 3D mapping of your property, along with a complete structural and vegetation inventory."
            />
          </div>
          <div className="flex my-10 mx-0 lg:my-0 lg:mx-9">
            <ImageCard
              imageSrc="https://res.cloudinary.com/htlasjvip/image/upload/v1629961434/website/Step2iPad_zvpcoq.jpg"
              title="Choose your project scope"
              body="From your 3D inspection, we’ll provide a menu of treatment options to get your property into compliance with regulators and insurers."
            />
          </div>
          <div className="flex">
            <ImageCard
              imageSrc="https://res.cloudinary.com/htlasjvip/image/upload/v1629939009/website/Step3_iahn4l.jpg"
              title="We handle the rest"
              body="Our marketplace of qualified, vetted contractors will bid on your job and carry out the work in a matter of weeks."
            />
          </div>
        </div>
      </div>

      {/* Service Offerings */}
      <div className="flex flex-col bg-frameColor">
        <ServiceCard
          text1="Defensible space and home hardening work by high quality, vetted contractors"
          text2="Our network includes arborists, landscapers, roofers, general contractors, and more. We conduct an in-depth vetting process to make sure we’re only matching you with the most qualified contractors in your area."
          workList={availableServices}
          imageSrc="https://res.cloudinary.com/htlasjvip/image/upload/v1629939162/website/CalistogaWide-min_bpe774.jpg"
        />
      </div>

      {/* Incentives & Insurance */}
      <div className="flex flex-col bg-frameColor">
        <div className="flex flex-col lg:flex-row m-auto mt-10 mb-10 lg:mb-16 p-4 lg:mt-20 lg: max-w-7xl">
          <div className="flex flex-col lg:flex-row  text-darkNavy">
            <CoinHandIcon className="mr-4 lg:mt-6 mb-4 lg:mb-0" />
            <div className="">
              <p className="h3 mb-2">
                Redeem eligible state and local wildfire defense incentives
              </p>
              <p className="body-text">
                Many municipalities offer grants and incentives for residents to
                complete wildfire defense work. We automatically surface
                qualified incentives to make sure you’re not leaving money on
                the table.
              </p>
            </div>
          </div>
          <div className="flex flex-col mt-10 lg:mt-0 lg:flex-row text-darkNavy ">
            <ClipBoardIcon className="mr-4 lg:mt-6 mb-4 lg:mb-0 -ml-4 lg:ml-0" />
            <div className="">
              <p className="h3 mb-2">
                Maintain insurance coverage and reduce your premium
              </p>
              <p className="body-text">
                We’ll complete your insurer’s wildfire readiness assessment to
                verify compliance. At your request, we’ll share this with your
                insurer so you can maintain coverage and lower your premium.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* 3D Mapping */}
      <div className="flex flex-col lg:flex-row bg-white lg:py-20">
        <div className="hidden lg:block max-w-200">
          <Image
            loader={() =>
              "https://res.cloudinary.com/htlasjvip/image/upload/v1629938542/website/Laptop-min_eairjz.jpg"
            }
            src="image"
            alt=""
            width={1592}
            height={1200}
          />
        </div>

        <div className="flex flex-col justify-center text-darkNavy lg:-m-18 lg:px-0 g:pr-20">
          <div className="lg:hidden">
            <Image
              className="w-full"
              loader={() =>
                "https://res.cloudinary.com/htlasjvip/image/upload/v1629940090/website/Rendering-Mid-min_zg3myu.jpg"
              }
              src="image"
              alt=""
              layout="responsive"
              width={1440}
              height={810}
            />
          </div>
          <div className="mx-4 mt-10 mb-14 lg:mb-0 lg:mt-0 lg:mr-24 max-w-145">
            <p className="h3">Contractor quotes without all the site visits</p>
            <p className="body-text mt-2">
              Our drone-based inspections produce a detailed, interactive 3D
              model of your home, allowing contractors to provide quotes without
              visiting your home. Say goodbye to the days of scheduling (and
              re-scheduling) weeks of clipboard-based inspections.
            </p>
            <div className="mt-4">
              <ButtonPrimary
                label="Get Started"
                onClick={() => {
                  window.location.href =
                    publicRuntimeConfig.appUrl + "/get-started";
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials (turn back autoPlay and interval (6000)) */}
      <div className="flex flex-col bg-frameColor">
        <div className="m-auto mt-16 mb-8 h2">Homeowner Testimonials</div>
        <Carousel
          infiniteLoop
          showStatus={false}
          showArrows={false}
          showThumbs={false}
          stopOnHover={true}
          swipeable={true}
          emulateTouch={true}
          autoPlay={true}
          interval={6000}
          className="mb-8"
        >
          <div className="mb-9 lg:mb-16">
            <TestimonialCard
              author="Sylvia W., Watsonville, CA"
              completedWorklist={[
                "Ember free zone",
                "Landscaped defensible space",
                "Fuel tank vegetation clearance",
              ]}
              imageSrc="https://res.cloudinary.com/htlasjvip/image/upload/v1629941010/website/Arborist-min_ly7nmu.jpg"
              text1="The whole process was incredibly streamlined..."
              text2="from start to finish. The whole process only took two weeks and the actual work on my property was done in just two days. It would have taken me far longer to complete the work without the Firemaps team."
            />
          </div>
          <div className="mb-9 lg:mb-16">
            <TestimonialCard
              author="Piper C., Calistoga, CA"
              completedWorklist={[
                "Roadway clearance",
                "Landscaped defensible space",
                "Ember-resistant gutter guards",
              ]}
              imageSrc="https://res.cloudinary.com/htlasjvip/image/upload/v1629940586/website/Piper-min_nghwal.jpg"
              text1="Protecting your home from wildfire is an overwhelming task..."
              text2="and Firemaps offers a turnkey solution and methodology that could be extremely useful for anyone with property at risk of wildfire."
            />
          </div>
          <div className="mb-9 lg:mb-16">
            <TestimonialCard
              author="Jeff J., Portola Valley, CA"
              completedWorklist={[
                "Ember free zone",
                "Landscaped defensible space",
                "Ember-resistant gutter guards",
              ]}
              imageSrc="https://res.cloudinary.com/htlasjvip/image/upload/v1629940845/website/GuttersWide-min_mixgp4.jpg"
              text1="The technology, experience, and knowledge..."
              text2="that Firemaps brings made me feel more confident in the work done to protect my home compared to previous experiences working directly with contractors. Beyond providing a plan, Firemaps provides the reasoning behind the suggested work."
            />
          </div>
        </Carousel>
      </div>

      {/* FAQ */}
      <div className="flex flex-col bg-white mb-10">
        <div className="mt-14 mb-10 lg:mb-16 mx-4">
          <div className="h2">Frequently Asked Questions</div>
        </div>
        <div className="flex flex-col justify-between mx-4 lg:m-auto lg:max-w-200 xl:max-w-300">
          {/* Row 1 */}
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="mb-10 lg:mb-16 lg:pr-16 lg:w-100 xl:w-150">
              <p className="h4">What is defensible space?</p>
              <p className="body-text pt-2">
                Defensible space is a buffer between your home and the
                vegetation that surrounds it. This helps to slow or stop the
                spread of wildfire and provides firefighters a safe area to
                defend your home.
              </p>
            </div>
            <div className="mb-10 lg:mb-16 lg:pl-16 lg:w-100 xl:w-150">
              <p className="h4">What is home hardening?</p>
              <p className="body-text pt-2">
                Flying embers from a wildfire can destroy homes up to a mile
                away. Home hardening is the process of protecting openings and
                using ember-resistant materials to increase the likelihood of
                survival when wildfire strikes.
              </p>
            </div>
          </div>

          {/* Row 2 */}
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="mb-10 lg:mb-16 lg:pr-16 lg:w-100 xl:w-150">
              <p className="h4">How do 3D inspections work?</p>
              <p className="body-text pt-2">
                Upon signing up, you can schedule your free inspection with one
                of our fire experts. Once our drone pilot maps the site,
                we&apos;ll send you a menu of treatment options to choose from
                within a week.
              </p>
            </div>
            <div className="mb-10 lg:mb-16 lg:pl-16 lg:w-100 xl:w-150">
              <p className="h4">How do you price contracting work?</p>
              <p className="body-text pt-2">
                Firemaps provides quotes directly from contractors with no
                customer-facing markups. We charge a small commission to
                contractors.
              </p>
            </div>
          </div>

          {/* Row 3 */}
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="mb-10 lg:mb-16 lg:pr-16 lg:w-100 xl:w-150">
              <p className="h4">Does Firemaps share my data?</p>
              <p className="body-text pt-2">
                We’ll never share your data with any insurance company or any
                other third party unless you explicitly ask us to do so.
              </p>
            </div>
            <div className="mb-10 lg:mb-16 lg:pl-16 lg:w-100 xl:w-150">
              <p className="h4">Where does Firemaps operate?</p>
              <p className="body-text pt-2">
                We currently operate in California. However, you can still sign
                up and get a free virtual consultation if you live out of state.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom CTA + Footer */}
      <Footer />
    </div>
  );
}
