import React from "react";
import PropTypes from "prop-types";

const ButtonPrimary = ({ label, twClasses, ...props }) => {
  return (
    <button
      className={`font-inter rounded-full bg-purple text-white font-semibold py-2 px-8 hover:text-white hover:bg-grey2 ${twClasses}`}
      {...props}
    >
      {label}
    </button>
  );
};

ButtonPrimary.propTypes = {
  label: PropTypes.string.isRequired,
  twClasses: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonPrimary.defaultProps = {
  onClick: undefined,
  twClasses: "",
};

export default ButtonPrimary;
