import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import ServiceList from "./ServiceList";

const ServiceCard = ({ imageSrc, text1, text2, workList }) => {
  return (
    <div className="">
      <div
        className="bg-frameColor bg-bottom bg-no-repeat bg-cover h-70 lg:h-100 m-auto"
        style={{
          backgroundImage: "url('" + imageSrc + "')",
        }}
      />

      {/* White Card */}
      <div className="bg-white overflow-hidden z-10 shadow-md rounded-xl flex flex-col lg:flex-row -mt-36 lg:-mt-44 lg:m-auto lg:card-desktop mx-4">
        {/* Full Content */}
        <div className="flex flex-col md:flex-row mt-10 mb-8 lg:my-14 mx-8 lg:mx-14">
          {/* Text Content */}
          <div className="md:mr-14 md:w-120 lg:w-129 lg:my-auto">
            <p className="h3">{text1}</p>
            <p className="body-medium mt-4">{text2}</p>
          </div>

          {/* Completed Work */}
          <ServiceList
            label="Available Services"
            services={workList}
            ctaLabel="View All"
            href="/services"
            twClasses="mt-6 sm:mx-auto sm:w-75 lg:mr-10 md:-mt-2.5"
          />
        </div>
      </div>
    </div>
  );
};

ServiceCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  workList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ServiceCard;
