import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";

const ImageCard = ({ imageSrc, title, body }) => {
  return (
    <div className="max-w-sm">
      <Image
        className="w-full rounded-lg shadow-md"
        loader={() => imageSrc}
        src="image"
        alt=""
        width={432}
        height={280}
      />
      <div className="mt-6">
        <div className="h4 mb-2">{title}</div>
        <p className="body-text">{body}</p>
      </div>
    </div>
  );
};

ImageCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default ImageCard;
