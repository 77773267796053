import React from "react";
import PropTypes from "prop-types";
import HomeIcon from "./HomeIcon";
import CheckMarkIcon from "./CheckMarkIcon";

const ServiceList = ({
  label,
  services,
  ctaLabel,
  href,
  twClasses,
  ...props
}) => {
  return (
    <div className={`${twClasses}`}>
      <div className="bg-grey6 rounded-xl mt-4 p-px">
        <HomeIcon twClasses="m-auto -mt-4 mb-3" />
        <div className="body-bold mb-2">{label}</div>
        <div className="mx-auto mb-7 w-57">
          {services.map((service, idx) => {
            return (
              <div className="flex worklist" key={idx}>
                <CheckMarkIcon />
                {service}
              </div>
            );
          })}
          <div className="mt-2 w-full text-center">
            <a href={`${href}`} className="workListLink">
              {ctaLabel}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

ServiceList.propTypes = {
  label: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  twClasses: PropTypes.string,
  onClick: PropTypes.func,
};

ServiceList.defaultProps = {
  twClasses: "",
};

export default ServiceList;
